.signup-form {
  background-color: #003265;
  border-radius: 25px;
  position: relative;
  max-width: 1270px;
  width: 100%;

  @media (min-width: 645px) {
    border-radius: 40px;
  }

  @media (min-width: 1024px) {
    border-radius: 20px;
    background-color: transparent;
  }

  &__title {
    display: none;

    @media (min-width: 1024px) {
      display: block;
      text-align: center;
      font-size: 140px;
      line-height: 1.1;
      font-weight: 800;
      font-style: italic;
      color: #FBD301;
      text-shadow: 0 0 50px #FED201;
      text-transform: uppercase;
      position: relative;
      z-index: 1;
      margin-bottom: 20px;

      &--white {
        --text-stroke-color: #FBD301;
        --text-color: #ffffff;
        position: relative;
        display: inline-block;
        text-transform: uppercase;
        color: var(--text-color);
        background: var(--text-color);
        background-clip: text;
        -webkit-background-clip: text;

        &:before {
          content: attr(data-text);
          position: absolute;
          inset: 0;
          z-index: -1;
          -webkit-text-stroke: 6px var(--text-stroke-color);
          background-clip: text;
          -webkit-background-clip: text;
          text-shadow: none;
        }
      }
    }
  }

  &__title-duplicate {
    display: none;

    @media (min-width: 1024px) {
      position: absolute;
      z-index: 0;
      display: block;
      text-align: center;
      font-size: 140px;
      font-style: italic;
      width: 100%;
      height: 100%;
      top: -7px;
      left: 16px;
      letter-spacing: 5px;
      text-shadow: none;
      opacity: 0.7;
      filter: blur(0.5px);
      font-weight: 200;
      color: #ffffff;
      text-stroke: 2px #FBD301;
      -webkit-text-stroke: 2px #FBD301;
    }
  }

  &__subtitle {
    display: none;

    @media (min-width: 1024px) {
      display: block;
      text-align: center;
      color: #6EB0F2;
      font-size: 40px;
      font-weight: 500;
      font-style: italic;
      text-shadow: 0px 0px 50px #043F7B, 0px 0px 40px #043F7B, 0px 0px 25px #043F7B, 0px 0px 10px #043F7B, 0px 0px 4px #043F7B;
      filter: blur(0.5px);
      margin-bottom: 30px;
      text-transform: uppercase;
    }
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 144px;
    z-index: 1;

    @media (min-width: 1024px) {
      max-width: 100%;
    }

    &--poker-coin {
      bottom: -65px;
      top: auto;
      left: -92px;

      @media (min-width: 1024px) {
        bottom: -118px;
        left: -106px;
      }
    }

    &--bitcoin {
      top: 36%;
      left: -92px;
      z-index: 0;

      @media (min-width: 1024px) {
        top: 48%;
        left: -150px;
        max-width: 100%;
      }
    }

    &--basketball {
      top: -78px;
      left: -80px;

      @media (min-width: 1024px) {
        top: 8%;
        left: -211px;
      }
    }

    &--coin {
      top: -82px;
      left: 45%;

      @media (min-width: 1024px) {
        top: 15%;
        left: 74%;
      }
    }

    &--cube {
      top: 10%;
      left: auto;
      right: -100px;

      @media (min-width: 1024px) {
        top: 33%;
        right: -177px;
      }
    }

    &--poker-coin-red {
      top: 46%;
      left: auto;
      right: -73px;
      z-index: 0;

      @media (min-width: 1024px) {
        top: 62%;
        right: -140px;
      }
    }

    &--football {
      top: auto;
      bottom: -80px;
      left: auto;
      right: -90px;

      @media (min-width: 1024px) {
        right: -133px;
        bottom: -240px;
      }
    }
  }
}
