.page {
  display: grid;
  place-content: center;
  margin: auto;
  padding: var(--v-offset-sm) 0;
  width: min(1140px, 100% - 40px);
  min-height: 100vh;

  &__form {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
