:root {
  --max-vw: 1440;
  --min-vw: 600;
  --max-fs: 1;
  --min-fs: .75;
  --min-fs-rem: calc(var(--min-fs) * 1rem);
  --max-fs-rem: calc(var(--max-fs) * 1rem);
  --min-vw-rem: calc((var(--min-vw) / 10) * 1rem);
  --slope: calc((var(--max-fs)  - var(--min-fs)) * (100vw - var(--min-vw-rem)) / ((var(--max-vw) / 10)  - (var(--min-vw) / 10)));
  --font-size-clamp: clamp(var(--min-fs-rem), calc(var(--min-fs-rem)  + var(--slope)), var(--max-fs-rem));
  --line-height-base: 1.5;
  --line-height-heading: 1.3em;
}

html {
  font-feature-settings: "kern" 1, "liga" 0;
  text-rendering: optimizelegibility;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  font-size: 10px;
}

body {
  font-family: var(--font-family-base, "Inter");
  font-size: var(--font-size-clamp);
  line-height: var(--line-height-base);
  font-weight: 400;
}

a {
  -webkit-text-decoration-skip-ink: auto;
  text-decoration-skip-ink: auto;
}

.display-large {
  font-size: 5.7em;
  line-height: var(--line-height-heading);
  margin-top: calc(var(--line-height-heading) / 2 * 2.5);
  margin-bottom: calc(var(--line-height-heading) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.08em;
}

.display-medium {
  font-size: 4.5em;
  line-height: var(--line-height-heading);
  margin-top: calc(var(--line-height-heading) / 2 * 2.5);
  margin-bottom: calc(var(--line-height-heading) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.07em;
}

.display-small {
  font-size: 3.6em;
  line-height: var(--line-height-heading);
  margin-top: calc(var(--line-height-heading) / 2 * 2.5);
  margin-bottom: calc(var(--line-height-heading) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.07em;
}

.headline-large {
  font-size: 3.2em;
  line-height: var(--line-height-heading);
  margin-top: calc(var(--line-height-heading) / 2 * 2.5);
  margin-bottom: calc(var(--line-height-heading) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.06em;
}

.headline-medium {
  font-size: 2.8em;
  line-height: var(--line-height-heading);
  margin-top: calc(var(--line-height-heading) / 2 * 2.5);
  margin-bottom: calc(var(--line-height-heading) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.06em;
}

.headline-small {
  font-size: 2.4em;
  line-height: var(--line-height-heading);
  margin-top: calc(var(--line-height-heading) / 2 * 2.5);
  margin-bottom: calc(var(--line-height-heading) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.05em;
}

.title-large {
  font-size: 2.2em;
  line-height: var(--line-height-heading);
  margin-top: calc(var(--line-height-heading) / 2 * 2.5);
  margin-bottom: calc(var(--line-height-heading) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.04em;
}

.title-medium {
  font-size: 1.6rem;
  line-height: var(--line-height-heading);
  margin-top: calc(var(--line-height-heading) / 2 * 2.5);
  margin-bottom: calc(var(--line-height-heading) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.03em;
  font-weight: 600;
}

.title-small {
  font-size: 1.4rem;
  line-height: var(--line-height-heading);
  margin-top: calc(var(--line-height-heading) / 2 * 2.5);
  margin-bottom: calc(var(--line-height-heading) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  font-weight: 600;
}

.label-large {
  text-wrap: balance;
  margin-bottom: 1rem;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2rem;
}

.label-medium {
  text-wrap: balance;
  margin-bottom: .8rem;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.6rem;
}

.label-small {
  text-wrap: balance;
  margin-bottom: .8rem;
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1.6rem;
}

.body-large {
  text-wrap: balance;
  margin-bottom: 1.2rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.body-medium {
  text-wrap: balance;
  margin-bottom: 1rem;
  font-size: 1.4rem;
  line-height: 2rem;
}

.body-small {
  text-wrap: balance;
  margin-bottom: .8rem;
  font-size: 1.2rem;
  line-height: 1.6rem;
}

ul, ol {
  padding-left: 0;
  font-size: 1.4rem;
  line-height: 2rem;
  list-style-position: outside;
}

.text-field-container {
  --tf-c-text-field: slategrey;
  --tf-c-text-helper: slategrey;
  --tf-c-inactive: darkgray;
  --tf-c-focused: dodgerblue;
  --tf-c-activated: gray;
  --tf-c-hover: dimgray;
  --tf-c-error: crimson;
  --tf-c-disabled: silver;
  --tf-c-placeholder: silver;
  --tf-c-border: var(--tf-c-inactive);
  --tf-font-size-field: 16px;
  --tf-font-size-helper: 12px;
  --tf-font-weight: 400;
  --tf-letter-spacing: .009375em;
  --tf-input-line-height: 23px;
  --tf-textarea-line-height: 24px;
  --tf-border-width-inactive: 1px;
  --tf-border-width-active: 2px;
  --tf-border-radius: 6px;
  --tf-transition-easing: cubic-bezier(0, .202, .204, 1);
  flex-direction: column;
  display: flex;
}

.notched-outline {
  z-index: 1;
  text-align: left;
  pointer-events: none;
  width: 100%;
  max-width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
}

.notched-outline__leading {
  pointer-events: none;
  border-top: var(--tf-border-width-inactive) solid;
  border-right: none;
  border-bottom: var(--tf-border-width-inactive) solid;
  border-left: var(--tf-border-width-inactive) solid;
  border-radius: var(--tf-border-radius) 0 0 var(--tf-border-radius);
  border-color: var(--tf-c-border);
  box-sizing: border-box;
  transition: border-color .225s var(--tf-transition-easing);
  width: 12px;
  height: 100%;
}

.notched-outline__notch {
  pointer-events: none;
  border-top: var(--tf-border-width-inactive) solid;
  border-bottom: var(--tf-border-width-inactive) solid;
  border-color: var(--tf-c-border);
  box-sizing: border-box;
  transition: border-color .225s var(--tf-transition-easing);
  flex: none;
  width: auto;
  max-width: calc(100% - 24px);
  height: 100%;
}

.input--focused .notched-outline__notch, .input--filled .notched-outline__notch, .textarea--focused .notched-outline__notch, .textarea--filled .notched-outline__notch {
  border-top: none;
  align-items: flex-start;
}

.notched-outline__trailing {
  pointer-events: none;
  border-top: var(--tf-border-width-inactive) solid;
  border-right: var(--tf-border-width-inactive) solid;
  border-bottom: var(--tf-border-width-inactive) solid;
  border-radius: 0 var(--tf-border-radius) var(--tf-border-radius) 0;
  border-left: none;
  border-color: var(--tf-c-border);
  box-sizing: border-box;
  transition: border-color .225s var(--tf-transition-easing);
  flex-grow: 1;
  height: 100%;
}

.input--focused .notched-outline__leading, .input--focused .notched-outline__notch, .input--focused .notched-outline__trailing, .input--filled .notched-outline__leading, .input--filled .notched-outline__notch, .input--filled .notched-outline__trailing, .textarea--focused .notched-outline__leading, .textarea--focused .notched-outline__notch, .textarea--focused .notched-outline__trailing, .textarea--filled .notched-outline__leading, .textarea--filled .notched-outline__notch, .textarea--filled .notched-outline__trailing {
  border-width: var(--tf-border-width-active);
}

.floating-label {
  right: initial;
  pointer-events: none;
  font-size: var(--tf-font-size-field);
  font-weight: var(--tf-font-weight);
  letter-spacing: var(--tf-letter-spacing);
  color: var(--tf-c-text-field);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0 0;
  transition: transform .15s var(--tf-transition-easing), color .15s var(--tf-transition-easing);
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: text;
  max-width: calc(100% - 30px);
  line-height: 23px;
  display: inline-block;
  position: absolute;
  left: 17px;
  overflow: hidden;
}

.input .floating-label {
  top: 50%;
  transform: translateY(-50%);
}

.textarea .floating-label {
  top: 13px;
}

.input--focused .floating-label, .input--filled .floating-label {
  transform: translateY(-33px)scale(.75);
}

.input--focused.input--leading-icon .floating-label, .input--filled.input--leading-icon .floating-label {
  transform: translateY(-33px)translateX(-27px)scale(.75);
}

.input--focused.input--dense .floating-label, .input--filled.input--dense .floating-label {
  transform: translateY(-27px)scale(.75);
}

.input--focused.input--large .floating-label, .input--filled.input--large .floating-label {
  transform: translateY(-44px)scale(.75);
}

.input--focused.input--dense.input--leading-icon .floating-label, .input--filled.input--dense.input--leading-icon .floating-label {
  transform: translateY(-27px)translateX(-27px)scale(.75);
}

.input--focused.input--large.input--leading-icon .floating-label, .input--filled.input--large.input--leading-icon .floating-label {
  transform: translateY(-44px)translateX(-27px)scale(.75);
}

.textarea--focused .floating-label, .textarea--filled .floating-label {
  transform: translateY(-22px)scale(.75);
}

.input {
  will-change: transform;
  border-radius: var(--tf-border-radius) var(--tf-border-radius) 0 0;
  height: 48px;
  display: inline-flex;
  position: relative;
  overflow: visible;
}

.input input {
  z-index: 1;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  appearance: none;
  font-size: var(--tf-font-size-field);
  line-height: var(--tf-input-line-height);
  font-weight: var(--tf-font-weight);
  letter-spacing: var(--tf-letter-spacing);
  color: var(--tf-c-text-field);
  background: none;
  border: none;
  border-radius: 0;
  align-self: flex-end;
  width: 100%;
  height: 48px;
  padding: 0 15px;
  display: flex;
}

.input input:focus {
  outline: none;
}

.input--dense, .input--dense input {
  height: 36px;
}

.input--large, .input--large input {
  height: 70px;
}

.input:hover:not(.input--error, .input--disabled, .input--focused) {
  --tf-c-border: var(--tf-c-hover);
}

.input.input--leading-icon > .input__icon, .input.input--trailing-icon > .input__icon {
  fill: var(--tf-c-text-field);
}

.input--focused {
  --tf-c-border: var(--tf-c-focused);
}

.input--focused label {
  color: var(--tf-c-focused);
}

.input--focused input {
  caret-color: var(--tf-c-focused);
}

.input--disabled {
  --tf-c-border: var(--tf-c-disabled);
}

.input--disabled label, .input--disabled input, .input--disabled + .input-helper {
  color: var(--tf-c-disabled);
}

.input--disabled.input--leading-icon > .input__icon, .input--disabled.input--trailing-icon > .input__icon {
  fill: var(--tf-c-disabled);
}

.input--error {
  --tf-c-border: var(--tf-c-error);
}

.input--error input {
  caret-color: var(--tf-c-error);
}

.input--error label, .input--error + .text-field-helper {
  color: var(--tf-c-error);
}

.input--error label.floating-label--required:after {
  content: "*";
  margin-left: 1px;
  font-size: 18px;
  line-height: 0;
}

.input--error.input--leading-icon > .input__icon, .input--error.input--trailing-icon > .input__icon {
  fill: var(--tf-c-error);
}

.input .input__icon {
  width: 24px;
  height: 24px;
}

.input.input--leading-icon .input__icon, .input.input--trailing-icon .input__icon {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.input.input--trailing-icon input {
  padding-left: 16px;
  padding-right: 48px;
}

.input.input--leading-icon input {
  padding-left: 48px;
  padding-right: 16px;
}

.input.input--leading-icon.input--trailing-icon input {
  padding-left: 48px;
  padding-right: 48px;
}

.input.input--trailing-icon .input__icon {
  left: initial;
  right: 12px;
}

.input.input--leading-icon .input__icon {
  left: 12px;
  right: initial;
}

.input.input--leading-icon.input--trailing-icon .input__icon ~ .input__icon {
  left: auto;
  right: 12px;
}

.input.input--leading-icon label {
  left: 44px;
  right: initial;
}

.input.input--trailing-icon label, .input.input--leading-icon label {
  max-width: calc(100% - 66px);
}

.input.input--leading-icon.input--trailing-icon label {
  max-width: calc(100% - 84px);
}

.textarea {
  will-change: transform;
  border-radius: var(--tf-border-radius) var(--tf-border-radius) 0 0;
  min-height: 80px;
  display: inline-flex;
  position: relative;
  overflow: visible;
}

.textarea textarea {
  resize: none;
  appearance: none;
  z-index: 1;
  font-size: var(--tf-font-size-field);
  line-height: var(--tf-textarea-line-height);
  font-weight: var(--tf-font-weight);
  letter-spacing: var(--tf-letter-spacing);
  color: var(--tf-c-text-field);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background: none;
  border: none;
  border-radius: 0;
  width: 100%;
  height: auto;
  margin: 8px 1px 2px 0;
  padding: 0 15px 4px;
  display: flex;
}

.textarea textarea:focus {
  outline: none;
}

.textarea:hover:not(.textarea--error, .textarea--disabled, .textarea--focused) {
  --tf-c-border: var(--tf-c-hover);
}

.textarea--focused {
  --tf-c-border: var(--tf-c-focused);
}

.textarea--focused label {
  color: var(--tf-c-focused);
}

.textarea--focused textarea {
  caret-color: var(--tf-c-focused);
}

.textarea--disabled {
  --tf-c-border: var(--tf-c-disabled);
}

.textarea--disabled label, .textarea--disabled textarea, .textarea--disabled + .textarea-helper {
  color: var(--tf-c-disabled);
}

.textarea--error {
  --tf-c-border: var(--tf-c-error);
}

.textarea--error textarea {
  caret-color: var(--tf-c-error);
}

.textarea--error label, .textarea--error + .text-field-helper {
  color: var(--tf-c-error);
}

.textarea--error label.floating-label--required:after {
  content: "*";
  margin-left: 1px;
  line-height: 0;
}

.text-field-helper {
  font-size: var(--tf-font-size-helper);
  color: var(--tf-c-text-helper);
  justify-content: flex-end;
  padding: 4px 17px 0;
  line-height: 1.3;
  display: flex;
}

.text-field-helper__text {
  flex: 1;
  padding-right: 10px;
}

.text-field-helper__counter {
  white-space: nowrap;
  text-align: right;
}

.text-field-container ::input-placeholder {
  color: var(--tf-c-placeholder);
  opacity: 0;
  transition: opacity .225s var(--tf-transition-easing);
}

.text-field-container :focus::input-placeholder {
  opacity: .65;
}

.text-field-container :input-placeholder {
  color: var(--tf-c-placeholder);
  opacity: 0;
  transition: opacity .225s var(--tf-transition-easing);
}

.text-field-container :focus:input-placeholder {
  opacity: .65;
}

.text-field-container ::placeholder {
  color: var(--tf-c-placeholder);
  opacity: 0;
  transition: opacity .225s var(--tf-transition-easing);
}

.text-field-container :focus::placeholder {
  opacity: .65;
}

.text-field-container :placeholder {
  color: var(--tf-c-placeholder);
  opacity: 0;
  transition: opacity .225s var(--tf-transition-easing);
}

.text-field-container :focus:placeholder {
  opacity: .65;
}

@supports (-webkit-overflow-scrolling: touch) {
  select, input[type="text"], textarea {
    font-size: 16px !important;
  }
}

.select-option {
  cursor: pointer;
  letter-spacing: .009375em;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  position: relative;
}

.select-option select {
  z-index: 1;
  opacity: 0;
  appearance: none;
  -webkit-tap-highlight-color: transparent;
  background-clip: padding-box;
  border: none;
  border-radius: 0;
  outline: none;
  margin: auto;
  position: absolute;
  inset: 0;
}

.select-option select:focus {
  -webkit-tap-highlight-color: transparent;
  border: none;
  outline: none;
}

.select-option:not(.select-option--mobile) select {
  display: none;
}

.select-option--opened {
  z-index: 2;
}

.select-option:after {
  pointer-events: none;
  content: "";
  background: var(--select-dropdown-arrow, url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMTAnIGhlaWdodD0nNScgdmlld0JveD0nNyAxMCAxMCA1JyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnPjxwYXRoIGZpbGwtcnVsZT0nZXZlbm9kZCcgb3BhY2l0eT0nLjU0JyBkPSdNNyAxMGw1IDUgNS01eicvPjwvc3ZnPg==")) center / 12px 12px no-repeat;
  width: 12px;
  height: 12px;
  margin: auto;
  display: block;
  position: absolute;
  inset: 0 14px 0 auto;
}

.select-option--opened:after {
  transform: rotate(.5turn);
}

.select-option .select-option-trigger {
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--so-color-trigger, Gray);
  max-width: 100%;
  height: 48px;
  padding-left: 17px;
  padding-right: 34px;
  line-height: 48px;
  overflow: hidden;
}

.select-option.select-option--dense .select-option-trigger {
  height: 36px;
  line-height: 36px;
}

.select-option .select-option-list {
  z-index: 2;
  border-radius: var(--so-border-radius-list, 5.5px);
  background-color: var(--so-background-list, White);
  box-shadow: var(--so-list-shadow, 0 5px 5px -3px #0003, 0 8px 10px 1px #00000024, 0 3px 14px 2px #0000001f);
  width: 100%;
  max-height: 210px;
  margin-top: 4px;
  padding-top: 8px;
  padding-bottom: 8px;
  display: none;
  position: absolute;
  inset: 100% auto auto 0;
  overflow: hidden visible;
}

.select-option .select-option-list::-webkit-scrollbar {
  width: 12px;
}

.select-option .select-option-list::-webkit-scrollbar-thumb {
  background-color: var(--so-color-scrollbar-thumb, Silver);
  border: 4px solid var(--so-color-scrollbar-track, White);
}

.select-option .select-option-list::-webkit-scrollbar-thumb:hover {
  background-color: var(--so-color-scrollbar-hover, Gray);
}

.select-option .select-option-list::-webkit-scrollbar-track {
  background-color: var(--so-color-scrollbar-track, White);
}

.select-option.select-option--downstairs .select-option-list {
  margin-top: unset;
  margin-bottom: 12px;
  inset: auto auto 100% 0;
}

.select-option.select-option--opened .select-option-list {
  display: block;
}

.select-option .select-option-list-item {
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: var(--so-background-option, White);
  color: var(--so-color-list-item, Gray);
  height: 48px;
  padding-left: 17px;
  padding-right: 17px;
  line-height: 48px;
  overflow: hidden;
}

.select-option .select-option-list-item--selected {
  --so-background-option: var(--so-background-option-selected, DodgerBlue);
  --so-color-list-item: var(--so-color-list-item-selected, White);
}

.select-option .select-option-list-item:not(.select-option .select-option-list-item--selected):hover {
  --so-background-option: var(--so-background-option-hovered, WhiteSmoke);
}

.select-option {
  --so-width-outline: 1px;
}

.select-option--labeled .notched-outline {
  pointer-events: none;
  z-index: 1;
  grid-template: 1fr / 12px auto 1fr;
  gap: 0;
  height: 100%;
  margin: auto;
  display: grid;
  position: absolute;
  inset: 0;
}

.select-option--labeled .notched-outline__leading {
  box-shadow: inset 0 var(--so-width-outline) 0 0 var(--so-color-outline, DarkGray), inset var(--so-width-outline) 0 0 0 var(--so-color-outline, DarkGray), inset 0 calc(var(--so-width-outline) * -1) 0 0 var(--so-color-outline, DarkGray);
  border-radius: var(--so-border-radius-select, 5.5px) 0 0 var(--so-border-radius-select, 5.5px);
  transform: translate3d(0, 0, 0);
}

.select-option--labeled .notched-outline__notch {
  box-shadow: inset 0 var(--so-width-outline) 0 0 var(--so-color-outline, DarkGray), inset 0 calc(var(--so-width-outline) * -1) 0 0 var(--so-color-outline, DarkGray);
}

.select-option--labeled .notched-outline__trailing {
  box-shadow: inset 0 var(--so-width-outline) 0 0 var(--so-color-outline, DarkGray), inset calc(var(--so-width-outline) * -1) 0 0 0 var(--so-color-outline, DarkGray), inset 0 calc(var(--so-width-outline) * -1) 0 0 var(--so-color-outline, DarkGray);
  border-radius: 0 var(--so-border-radius-select, 5.5px) var(--so-border-radius-select, 5.5px) 0;
  transform: translate3d(0, 0, 0);
}

.select-option--labeled.select-option--opened .notched-outline__notch, .select-option--labeled.select-option--selected .notched-outline__notch {
  box-shadow: inset 0 calc(var(--so-width-outline) * -1) 0 0 var(--so-color-outline, DarkGray);
}

.select-option--unlabeled {
  box-shadow: inset 0 0 0 var(--so-width-outline) var(--so-color-outline, DarkGray);
  border-radius: var(--so-border-radius-select, 5.5px);
  transform: translate3d(0, 0, 0);
}

.select-option:not(.select-option--opened):hover {
  --so-color-outline: var(--so-color-outline-hover, SlateGrey);
}

.select-option:not(.select-option--opened):hover.select-option--mobile, .select-option--opened {
  --so-color-outline: var(--so-color-outline-opened, DodgerBlue);
  --so-width-outline: 2px;
}

.select-option .floating-label {
  pointer-events: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--so-color-label, Gray);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0 0;
  max-width: calc(100% - 51px);
  transition: transform .15s cubic-bezier(0, .202, .204, 1), color .15s cubic-bezier(0, .202, .204, 1);
  display: block;
  position: absolute;
  inset: 50% auto auto 17px;
  overflow: hidden;
  transform: translateY(-50%);
}

.select-option.select-option--selected .floating-label {
  --so-color-label: var(--so-color-label-selected, DarkGray);
  transform: translateY(-30px)scale(.75);
}

.select-option.select-option--opened .floating-label {
  --so-color-label: var(--so-color-label-opened, DodgerBlue);
  transform: translateY(-30px)scale(.75);
}

.select-option.select-option--opened.select-option--dense .floating-label, .select-option.select-option--selected.select-option--dense .floating-label {
  transform: translateY(-24px)scale(.75);
}

.tickbox {
  --tickbox-color-active-h: 210;
  --tickbox-color-active-s: 100%;
  --tickbox-color-active-l: 56%;
  --tickbox-color-active-a: 1;
  --tickbox-color-active: hsla(var(--tickbox-color-active-h) var(--tickbox-color-active-s) var(--tickbox-color-active-l) / var(--tickbox-color-active-a));
  --tickbox-color-inactive-h: 0;
  --tickbox-color-inactive-s: 0%;
  --tickbox-color-inactive-l: 66%;
  --tickbox-color-inactive-a: 1;
  --tickbox-color-inactive: hsla(var(--tickbox-color-inactive-h) var(--tickbox-color-inactive-s) var(--tickbox-color-inactive-l) / var(--tickbox-color-inactive-a));
  --tickbox-color-active-highlight: hsla(var(--tickbox-color-active-h) var(--tickbox-color-active-s) var(--tickbox-color-active-l) / 26%);
  --tickbox-color-inactive-highlight: hsla(var(--tickbox-color-inactive-h) var(--tickbox-color-inactive-s) var(--tickbox-color-inactive-l) / 26%);
  --tickbox-color-disabled: silver;
  --tickbox-transition-duration: .475s;
  --tickbox-transition-timing: cubic-bezier(.4, .08, .38, .9);
  --tickbox-label-font-size: 15px;
  --tickbox-desc-font-size: 12px;
  --tickbox-label-font-color: black;
  --tickbox-desc-font-color: gray;
  --tickbox-disabled-font-color: silver;
  pointer-events: none;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 2px 0;
  display: inline-flex;
  position: relative;
}

.tickbox input {
  pointer-events: auto;
  appearance: none;
  cursor: pointer;
  backface-visibility: hidden;
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  background: none;
  width: 48px;
  min-width: 48px;
  height: 48px;
  min-height: 48px;
  position: relative;
}

.tickbox input[type="checkbox"]:not([role="switch"]), .tickbox input[type="radio"]:not([role="switch"]) {
  margin-left: -14px;
  margin-right: 10px;
}

.tickbox input[role="switch"] {
  margin-left: -10px;
  margin-right: 15px;
}

.tickbox input:disabled {
  pointer-events: none;
  cursor: default;
}

.tickbox input:before, .tickbox input:after {
  content: "";
  margin: auto;
  position: absolute;
  inset: 0;
}

.tickbox--rtl {
  justify-content: space-between;
}

.tickbox--rtl input {
  order: 2;
}

.tickbox--rtl input[type="checkbox"]:not([role="switch"]), .tickbox--rtl input[type="radio"]:not([role="switch"]) {
  margin-left: 10px;
  margin-right: -14px;
}

.tickbox--rtl input[role="switch"] {
  margin-left: 15px;
  margin-right: -10px;
}

.tickbox--rtl label {
  order: 1;
}

.tickbox label {
  font-weight: var(--tickbox-label-font-weight, 400);
  line-height: 150%;
  font-size: var(--tickbox-label-font-size);
  color: var(--tickbox-label-font-color);
  letter-spacing: .01px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: auto;
  flex-direction: column;
  justify-content: center;
  max-width: 100%;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.tickbox label span {
  padding: 1px 0;
}

.tickbox label span:first-child {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.tickbox label span + span {
  line-height: 125%;
  font-size: var(--tickbox-desc-font-size);
  color: var(--tickbox-desc-font-color);
  font-weight: var(--tickbox-desc-font-weight, 400);
}

.tickbox input:disabled + label {
  pointer-events: none;
  color: var(--tickbox-disabled-font-color);
}

.tickbox input:disabled + label span + span {
  color: var(--tickbox-disabled-font-color);
}

.tickbox input[type="checkbox"]:not(:disabled, [role="switch"]):focus, .tickbox input[type="radio"]:not(:disabled, [role="switch"]):focus {
  appearance: none;
  border-radius: 100px;
}

.tickbox input[type="checkbox"]:not(:disabled, [role="switch"]):focus:checked, .tickbox input[type="radio"]:not(:disabled, [role="switch"]):focus:checked {
  animation: rippleChecked var(--tickbox-transition-duration) var(--tickbox-transition-timing) forwards;
}

.tickbox input[type="checkbox"]:not(:disabled, [role="switch"]):focus:not(:checked), .tickbox input[type="radio"]:not(:disabled, [role="switch"]):focus:not(:checked) {
  animation: rippleUnchecked var(--tickbox-transition-duration) var(--tickbox-transition-timing) forwards;
}

@keyframes rippleChecked {
  from {
    box-shadow: inset 0 0 0 24px var(--tickbox-color-active-highlight);
  }

  to {
    box-shadow: inset 0 0 0 24px #0000;
  }
}

@keyframes rippleUnchecked {
  from {
    box-shadow: inset 0 0 0 24px var(--tickbox-color-inactive-highlight);
  }

  to {
    box-shadow: inset 0 0 0 24px #0000;
  }
}

.tickbox input[type="checkbox"]:not([role="switch"]):before, .tickbox input[type="checkbox"]:not([role="switch"]):after {
  --tickbox-checkbox: var(--tickbox-color-inactive);
  border-radius: var(--tickbox-checkbox-radius, 2px);
  border-color: var(--tickbox-checkbox);
  transition: border-color 90ms, background-color 90ms, transform .225s;
}

.tickbox input[type="checkbox"]:not([role="switch"]):before {
  border-width: var(--tickbox-border-width, 2px);
  border-style: solid;
  width: 20px;
  height: 20px;
}

.tickbox input[type="checkbox"]:not([role="switch"]):after {
  opacity: 0;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgZmlsbD0iI2ZmZiI+PHBhdGggZD0ibTcgMTMuNC00LTRMNC40IDggNyAxMC42IDEzLjYgNCAxNSA1LjRsLTggOFoiLz48L3N2Zz4=");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 18px 18px;
  width: 20px;
  height: 20px;
  transform: rotate(-25deg);
}

.tickbox input[type="checkbox"]:not([role="switch"]):checked:before {
  --tickbox-checkbox: var(--tickbox-color-active);
  background-color: var(--tickbox-checkbox);
}

.tickbox input[type="checkbox"]:not([role="switch"]):checked:after {
  opacity: 1;
  transform: rotate(0);
}

.tickbox input[type="checkbox"]:not([role="switch"]):disabled:before, .tickbox input[type="checkbox"]:not([role="switch"]):disabled:after {
  --tickbox-checkbox: var(--tickbox-color-disabled);
}

.tickbox input[type="radio"]:not([role="switch"]):before, .tickbox input[type="radio"]:not([role="switch"]):after {
  --tickbox-radio: var(--tickbox-color-inactive);
  transition: border-color 90ms, background-color 90ms, transform 90ms;
}

.tickbox input[type="radio"]:not([role="switch"]):before {
  background-color: var(--tickbox-radio);
  border-radius: var(--tickbox-radio-mark-radius, 10px);
  width: 10px;
  height: 10px;
  transform: scale(0);
}

.tickbox input[type="radio"]:not([role="switch"]):after {
  border-width: var(--tickbox-border-width, 2px);
  border-style: solid;
  border-color: var(--tickbox-radio);
  border-radius: var(--tickbox-radio-border-radius, 20px);
  width: 20px;
  height: 20px;
}

.tickbox input[type="radio"]:not([role="switch"]):checked:before, .tickbox input[type="radio"]:not([role="switch"]):checked:after {
  --tickbox-radio: var(--tickbox-color-active);
}

.tickbox input[type="radio"]:not([role="switch"]):checked:before {
  transform: scale(1);
}

.tickbox input[type="radio"]:not([role="switch"]):disabled:before, .tickbox input[type="radio"]:not([role="switch"]):disabled:after {
  --tickbox-radio: var(--tickbox-color-disabled);
}

.tickbox input[role="switch"] {
  width: 55px;
  min-width: 55px;
}

.tickbox input[role="switch"]:before, .tickbox input[role="switch"]:after {
  --tickbox-switch-color: var(--tickbox-switch-color-inactive, var(--tickbox-color-inactive));
  border-color: var(--tickbox-switch-color);
  transition: border-color .125s, background-color .125s, transform .125s;
}

.tickbox input[role="switch"]:before {
  border-radius: var(--tickbox-switch-mark-radius, 10px);
  background-color: var(--tickbox-switch-color);
  width: 10px;
  height: 10px;
  transform: translateX(-6px);
}

.tickbox input[role="switch"]:after {
  border-radius: var(--tickbox-switch-border-radius, 24px);
  border-width: var(--tickbox-border-width, 2px);
  background-color: #0000;
  border-style: solid;
  width: 36px;
  height: 24px;
}

.tickbox input[role="switch"]:checked:before, .tickbox input[role="switch"]:checked:after {
  --tickbox-switch-color: var(--tickbox-switch-color-active, var(--tickbox-color-active));
}

.tickbox input[role="switch"]:checked:before {
  transform: translateX(6px)scale(1.4);
}

.tickbox input[role="switch"]:disabled:before, .tickbox input[role="switch"]:disabled:after {
  --tickbox-switch-color: var(--tickbox-switch-color-disabled, var(--tickbox-color-disabled));
}

:where(:not(html, iframe, canvas, img, svg, video, audio):not(svg *, symbol *)) {
  all: unset;
  display: revert;
}

*, :before, :after {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  text-size-adjust: none;
}

a, button {
  cursor: revert;
}

ol, ul, menu, summary {
  list-style: none;
}

img {
  max-block-size: 100%;
  max-inline-size: 100%;
}

table {
  border-collapse: collapse;
}

input, textarea {
  -webkit-user-select: auto;
}

textarea {
  white-space: revert;
}

meter {
  appearance: revert;
}

:where(pre) {
  all: revert;
  box-sizing: border-box;
}

::placeholder {
  color: unset;
}

:where([hidden]) {
  display: none;
}

:where([contenteditable]:not([contenteditable="false"])) {
  -moz-user-modify: read-write;
  -webkit-user-modify: read-write;
  overflow-wrap: break-word;
  -webkit-line-break: after-white-space;
  -webkit-user-select: auto;
}

:where([draggable="true"]) {
  -webkit-user-drag: element;
}

:where(dialog:modal) {
  all: revert;
  box-sizing: border-box;
}

::-webkit-details-marker {
  display: none;
}

:root {
  --z-index-header: 1;
  --c-white: #fff;
  --v-offset-sm: min(50px, 3vmax);
  --v-offset-md: min(100px, 5vmax);
  --v-offset-lg: min(160px, 8vmax);
  --max-vw: 1600;
  --min-vw: 600;
  --max-fs: 1;
  --min-fs: .6;
  --font-family-base: "Inter", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --line-height-base: 1.5;
  --line-height-heading: 1.3em;
  --c-scrollbar-track: Black;
  --c-scrollbar-thumb: Gray;
  --c-scrollbar-hover: White;
}

@media (width >= 992px) {
  :root {
    --v-offset-sm: min(40px, 2vmax);
    --v-offset-md: min(80px, 4vmax);
  }
}

html, body {
  position: relative;
}

body {
  background-color: #000;
  width: 100vw;
  overflow: hidden auto;
}

@supports (min-height: 100dvh) {
  body {
    min-height: 100dvh;
  }
}

@supports not (min-height: 100dvh) {
  body {
    min-height: 100vh;
  }
}

body::-webkit-scrollbar {
  opacity: 0;
  width: 12px;
}

body::-webkit-scrollbar-thumb {
  background-color: var(--c-scrollbar-thumb, var(--c-canary-yellow));
  border: 4px solid var(--c-scrollbar-track, var(--c-blue));
  border-radius: 2px;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: var(--c-scrollbar-hover, var(--c-text-greenish-yellow));
}

body::-webkit-scrollbar-track {
  background-color: var(--c-scrollbar-track, var(--c-blue));
}

.display-large {
  color: #a9a9a9;
  margin: 20px 0 0;
  font-weight: 900;
}

.page {
  padding: var(--v-offset-sm) 0;
  place-content: center;
  width: min(1140px, 100% - 40px);
  min-height: 100vh;
  margin: auto;
  display: grid;
}

.page__form {
  justify-content: center;
  width: 100%;
  display: flex;
}

.svg-icon--ganta {
  aspect-ratio: 1;
  fill: #a9a9a9;
  width: clamp(160px, 22vmin, 300px);
}

.form {
  background-color: #003265;
  border-radius: 25px;
  gap: 5px 0;
  padding: 49px 27px;
  display: grid;
  position: relative;
}

@media (width >= 645px) {
  .form {
    border-radius: 40px;
    gap: 10px 0;
    padding: 55px 50px;
  }
}

@media (width >= 1024px) {
  .form {
    background-color: #043f7b;
    border-radius: 20px;
    gap: 25px 0;
    padding: 43px 50px;
  }
}

.form__title {
  color: #fed201;
  text-align: center;
  text-shadow: 0 0 11.5px #fed201;
  text-transform: uppercase;
  font-size: 36px;
  font-style: italic;
  font-weight: 500;
  line-height: 1.2;
  display: block;
}

@media (width >= 645px) {
  .form__title {
    font-size: 45px;
  }
}

@media (width >= 1024px) {
  .form__title {
    text-transform: uppercase;
    color: #fff;
    font-size: 40px;
    font-style: unset;
    text-shadow: none;
    margin-bottom: 20px;
  }
}

.form__title--white {
  color: #fff;
}

.form__title--desk {
  display: none;
}

@media (width >= 1024px) {
  .form__title--desk {
    display: block;
  }

  .form__title--mob {
    display: none;
  }
}

.form__subtitle {
  color: #468edd;
  text-align: center;
  text-transform: uppercase;
  margin-top: 4px;
  margin-bottom: 26px;
  font-size: 18px;
  font-style: italic;
  font-weight: 500;
  line-height: 1.2;
  display: block;
}

@media (width >= 645px) {
  .form__subtitle {
    margin-top: 0;
    margin-bottom: 68px;
    font-size: 23px;
  }
}

@media (width >= 1024px) {
  .form__subtitle {
    display: none;
  }
}

.form .input {
  background-color: #042d56;
  border-radius: 0;
}

@media (width >= 645px) {
  .form .input {
    height: 64px;
  }
}

@media (width >= 1024px) {
  .form .input {
    height: 64px;
  }
}

.form .input input {
  font-size: 14px;
}

@media (width >= 645px) {
  .form .input input {
    font-size: 25px;
  }
}

.form .input .floating-label {
  color: #0f73bd;
  font-size: 14px;
  font-weight: 500;
}

@media (width >= 645px) {
  .form .input .floating-label {
    font-size: 25px;
    font-weight: 700;
  }
}

@media (width >= 1024px) {
  .form .input .floating-label {
    font-weight: 400;
  }
}

.form .notched-outline__leading, .form .notched-outline__notch, .form .notched-outline__trailing {
  border: none;
}

.form input:-webkit-autofill {
  -webkit-text-fill-color: #000;
  -webkit-background-clip: text;
  transition: background-color 5000s ease-in-out;
  box-shadow: inset 0 0 20px 20px #0000;
}

.form input:-webkit-autofill:hover {
  -webkit-text-fill-color: #000;
  -webkit-background-clip: text;
  transition: background-color 5000s ease-in-out;
  box-shadow: inset 0 0 20px 20px #0000;
}

.form input:-webkit-autofill:focus {
  -webkit-text-fill-color: #000;
  -webkit-background-clip: text;
  transition: background-color 5000s ease-in-out;
  box-shadow: inset 0 0 20px 20px #0000;
}

.form input:-webkit-autofill:active {
  -webkit-text-fill-color: #000;
  -webkit-background-clip: text;
  transition: background-color 5000s ease-in-out;
  box-shadow: inset 0 0 20px 20px #0000;
}

.select-option {
  box-shadow: none;
  background-color: #042d56;
  border-radius: 0;
  font-size: 16px;
}

@media (width >= 645px) {
  .select-option {
    height: 64px;
  }
}

.select-option:after {
  background: var(--select-dropdown-arrow, url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOSIgaGVpZ2h0PSI5IiBmaWxsPSJub25lIj48cGF0aCBzdHJva2U9IiMwRjczQkQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyIiBkPSJtMSAxIDguNSA2TDE4IDEiLz48L3N2Zz4=")) center / 18px 8px no-repeat;
  width: 18px;
  height: 18px;
  inset: 0 26px 0 auto;
}

.select-option--country .select-option-trigger, .select-option--country .select-option-list-item {
  color: #0f73bd;
  padding-left: 54px;
}

@media (width >= 645px) {
  .select-option--country .select-option-trigger, .select-option--country .select-option-list-item {
    height: 64px;
    line-height: 64px;
  }
}

.select-option--country .select-option-trigger--rub, .select-option--country .select-option-list-item--rub {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIxOCIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iI2ZmZiIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMCAwaDI0djZIMFYwWiIgY2xpcC1ydWxlPSJldmVub2RkIi8+PHBhdGggc3Ryb2tlPSIjRDBEMEQwIiBkPSJNLjUuNWgyM3YxN0guNVYuNVoiLz48cGF0aCBmaWxsPSIjMDAzOUE2IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wIDZoMjR2NkgwVjZaIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiLz48cGF0aCBmaWxsPSIjRDUyQjFFIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wIDEyaDI0djZIMHYtNloiIGNsaXAtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==");
}

.select-option--country .select-option-trigger--kzt, .select-option--country .select-option-list-item--kzt {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIxOCIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iIzAxQTVDOCIgZD0iTTAgMGgyNHYxOEgweiIvPjxwYXRoIHN0cm9rZT0iI0ZGRTcwMCIgc3Ryb2tlLXdpZHRoPSIxLjI1IiBkPSJtOCAxMSA0LjUgM00xMS41IDE0bDQuNS0zIi8+PGNpcmNsZSBjeD0iMTIiIGN5PSI4IiByPSIzIiBmaWxsPSIjRkZFNzAwIi8+PC9zdmc+");
}

.select-option--country .select-option-trigger--rub, .select-option--country .select-option-trigger--kzt, .select-option--country .select-option-list-item--rub, .select-option--country .select-option-list-item--kzt {
  background-position: 18px;
  background-repeat: no-repeat;
  background-size: 24px 18px;
}

.auth-rules {
  --tickbox-checkbox-radius: 5px;
  margin: 10px 0 0 3px;
  padding: 0;
  display: flex;
}

@media (width >= 645px) {
  .auth-rules {
    margin: 28px 0 0 8px;
  }
}

@media (width >= 1024px) {
  .auth-rules {
    background-color: #042d56;
    height: 64px;
    margin-top: 0;
    margin-left: 0;
    padding: 17px 88px;
  }
}

.auth-rules label {
  color: #fff;
  margin-left: 0;
  font-size: 13px;
  line-height: 1.1;
}

@media (width >= 645px) {
  .auth-rules label {
    margin-left: 20px;
    font-size: 23px;
  }
}

@media (width >= 1024px) {
  .auth-rules label {
    margin-left: 5px;
    font-size: 19px;
    line-height: 1.2;
  }
}

.auth-rules label span:first-child {
  white-space: unset;
  text-overflow: unset;
  overflow: auto;
}

.auth-rules a {
  color: #62beff;
}

@media (width >= 1024px) {
  .auth-rules a {
    color: #fff;
  }
}

.auth-rules a:hover {
  text-decoration: none;
}

.btn-signup-send {
  --button-color: White;
  --button-background: #fa5501;
  background: var(--button-background);
  color: var(--button-color);
  box-sizing: border-box;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  touch-action: manipulation;
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  text-transform: uppercase;
  border: none;
  border-radius: 100px;
  outline: none;
  place-content: center;
  width: 100%;
  max-width: 250px;
  height: 48px;
  margin: 20px auto 0;
  padding: 0 22px;
  font-family: inherit;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  text-decoration: none;
  display: grid;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 32px #fa5501f2;
}

.btn-signup-send--desk {
  display: none;
}

@media (width >= 1024px) {
  .btn-signup-send--desk {
    display: grid;
  }

  .btn-signup-send--mobi {
    display: none;
  }
}

@media (width >= 645px) {
  .btn-signup-send {
    max-width: 395px;
    height: 73px;
    margin: 85px auto 0;
    font-size: 32px;
  }
}

@media (width >= 1024px) {
  .btn-signup-send {
    max-width: 375px;
    height: 55px;
    margin: 0 0 0 auto;
    font-size: 20px;
  }
}

@media (hover: hover) and (pointer: fine) {
  .btn-signup-send:before {
    content: "";
    margin: auto;
    transition: opacity .375s;
    position: absolute;
    inset: 0;
  }

  .btn-signup-send:hover:before {
    opacity: 0;
  }

  .btn-signup-send span {
    z-index: 1;
    position: relative;
  }
}

.signup-form {
  background-color: #003265;
  border-radius: 25px;
  width: 100%;
  max-width: 1270px;
  position: relative;
}

@media (width >= 645px) {
  .signup-form {
    border-radius: 40px;
  }
}

@media (width >= 1024px) {
  .signup-form {
    background-color: #0000;
    border-radius: 20px;
  }
}

.signup-form__title {
  display: none;
}

@media (width >= 1024px) {
  .signup-form__title {
    text-align: center;
    color: #fbd301;
    text-shadow: 0 0 50px #fed201;
    text-transform: uppercase;
    z-index: 1;
    margin-bottom: 20px;
    font-size: 140px;
    font-style: italic;
    font-weight: 800;
    line-height: 1.1;
    display: block;
    position: relative;
  }

  .signup-form__title--white {
    --text-stroke-color: #fbd301;
    --text-color: #fff;
    text-transform: uppercase;
    color: var(--text-color);
    background: var(--text-color);
    -webkit-background-clip: text;
    background-clip: text;
    display: inline-block;
    position: relative;
  }

  .signup-form__title--white:before {
    content: attr(data-text);
    z-index: -1;
    -webkit-text-stroke: 6px var(--text-stroke-color);
    text-shadow: none;
    -webkit-background-clip: text;
    background-clip: text;
    position: absolute;
    inset: 0;
  }
}

.signup-form__title-duplicate {
  display: none;
}

@media (width >= 1024px) {
  .signup-form__title-duplicate {
    z-index: 0;
    text-align: center;
    letter-spacing: 5px;
    text-shadow: none;
    opacity: .7;
    filter: blur(.5px);
    color: #fff;
    text-stroke: 2px #fbd301;
    -webkit-text-stroke: 2px #fbd301;
    width: 100%;
    height: 100%;
    font-size: 140px;
    font-style: italic;
    font-weight: 200;
    display: block;
    position: absolute;
    top: -7px;
    left: 16px;
  }
}

.signup-form__subtitle {
  display: none;
}

@media (width >= 1024px) {
  .signup-form__subtitle {
    text-align: center;
    color: #6eb0f2;
    text-shadow: 0 0 50px #043f7b, 0 0 40px #043f7b, 0 0 25px #043f7b, 0 0 10px #043f7b, 0 0 4px #043f7b;
    filter: blur(.5px);
    text-transform: uppercase;
    margin-bottom: 30px;
    font-size: 40px;
    font-style: italic;
    font-weight: 500;
    display: block;
  }
}

.signup-form__image {
  z-index: 1;
  max-width: 144px;
  position: absolute;
  top: 0;
  left: 0;
}

@media (width >= 1024px) {
  .signup-form__image {
    max-width: 100%;
  }
}

.signup-form__image--poker-coin {
  top: auto;
  bottom: -65px;
  left: -92px;
}

@media (width >= 1024px) {
  .signup-form__image--poker-coin {
    bottom: -118px;
    left: -106px;
  }
}

.signup-form__image--bitcoin {
  z-index: 0;
  top: 36%;
  left: -92px;
}

@media (width >= 1024px) {
  .signup-form__image--bitcoin {
    max-width: 100%;
    top: 48%;
    left: -150px;
  }
}

.signup-form__image--basketball {
  top: -78px;
  left: -80px;
}

@media (width >= 1024px) {
  .signup-form__image--basketball {
    top: 8%;
    left: -211px;
  }
}

.signup-form__image--coin {
  top: -82px;
  left: 45%;
}

@media (width >= 1024px) {
  .signup-form__image--coin {
    top: 15%;
    left: 74%;
  }
}

.signup-form__image--cube {
  top: 10%;
  left: auto;
  right: -100px;
}

@media (width >= 1024px) {
  .signup-form__image--cube {
    top: 33%;
    right: -177px;
  }
}

.signup-form__image--poker-coin-red {
  z-index: 0;
  top: 46%;
  left: auto;
  right: -73px;
}

@media (width >= 1024px) {
  .signup-form__image--poker-coin-red {
    top: 62%;
    right: -140px;
  }
}

.signup-form__image--football {
  inset: auto -90px -80px auto;
}

@media (width >= 1024px) {
  .signup-form__image--football {
    bottom: -240px;
    right: -133px;
  }
}

.tickbox input[type="checkbox"]:not([role="switch"]) {
  margin-right: 0;
}

@media (width >= 1024px) {
  .tickbox input[type="checkbox"]:not([role="switch"]) {
    margin: 0;
  }
}

.tickbox input[type="checkbox"]:not([role="switch"]):after {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgZmlsbD0ibm9uZSI+PHBhdGggc3Ryb2tlPSIjZmZmIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS13aWR0aD0iMyIgZD0ibTEuNSA4LjI0MyA0LjY0NyA2Ljk3NmExIDEgMCAwIDAgMS42NzctLjAyTDE2LjUgMS41Ii8+PC9zdmc+");
  background-size: contain;
  width: 12px;
  height: 11px;
}

@media (width >= 1024px) {
  .tickbox input[type="checkbox"]:not([role="switch"]):after {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMyIgaGVpZ2h0PSIyMCIgZmlsbD0ibm9uZSI+PHBhdGggc3Ryb2tlPSIjMDg0Qjk0IiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIiBzdHJva2Utd2lkdGg9IjQiIGQ9Ik0zLjc1IDExLjg0MiAxMC40MjggMTcgMTkuNzUgMyIvPjwvc3ZnPg==");
    width: 16px;
    height: 14px;
  }
}

.tickbox input[type="checkbox"]:not([role="switch"]):before {
  background-color: #0000;
  border: 2px solid #61bfff;
  border-radius: 0;
  width: 25px;
  height: 25px;
}

@media (width >= 645px) {
  .tickbox input[type="checkbox"]:not([role="switch"]):before {
    width: 36px;
    height: 36px;
  }
}

@media (width >= 1024px) {
  .tickbox input[type="checkbox"]:not([role="switch"]):before {
    background-color: #fff;
    border: 3px solid #cfcfcf;
    width: 30px;
    height: 30px;
  }
}

.tickbox input[type="checkbox"]:not([role="switch"]):checked:before {
  background-color: #0000;
}

@media (width >= 1024px) {
  .tickbox input[type="checkbox"]:not([role="switch"]):checked:before {
    background-color: #fff;
  }
}
/*# sourceMappingURL=index.ce116055.css.map */
