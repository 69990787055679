.form {
  position: relative;
  display: grid;
  gap: 5px 0;
  background-color: #003265;
  padding: 49px 27px;
  border-radius: 25px;

  @media (min-width: 645px) {
    border-radius: 40px;
    padding: 55px 50px;
    gap: 10px 0;
  }

  @media (min-width: 1024px) {
    border-radius: 20px;
    padding: 43px 50px;
    gap: 25px 0;
    background-color: #043F7B;
  }

  &__title {
    font-size: 36px;
    line-height: 1.2;
    font-weight: 500;
    font-style: italic;
    color: #FED201;
    display: block;
    text-align: center;
    text-shadow: 0 0 11.5px #FED201;
    text-transform: uppercase;

    @media (min-width: 645px) {
      font-size: 45px;
    }

    @media (min-width: 1024px) {
      text-transform: uppercase;
      color: #ffffff;
      font-size: 40px;
      font-style: unset;
      text-shadow: none;
      margin-bottom: 20px;
    }

    &--white {
      color: #ffffff;
    }

    &--desk {
      display: none;

      @media (min-width: 1024px) {
        display: block;
      }
    }

    &--mob {
      @media (min-width: 1024px) {
        display: none;
      }
    }
  }

  &__subtitle {
    color: #468EDD;
    font-size: 18px;
    line-height: 1.2;
    font-weight: 500;
    font-style: italic;
    text-align: center;
    display: block;
    text-transform: uppercase;
    margin-top: 4px;
    margin-bottom: 26px;

    @media (min-width: 645px) {
      font-size: 23px;
      margin-top: 0;
      margin-bottom: 68px;
    }

    @media (min-width: 1024px) {
      display: none;
    }
  }

  .input {
    //height: 36px;
    background-color: #042D56;
    border-radius: 0;

    @media (min-width: 645px) {
      height: 64px;
    }

    @media (min-width: 1024px) {
      height: 64px;
    }

    input {
      font-size: 14px;

      @media (min-width: 645px) {
        font-size: 25px;
      }
    }

    .floating-label {
      color: #0F73BD;
      font-weight: 500;
      font-size: 14px;

      @media (min-width: 645px) {
        font-size: 25px;
        font-weight: 700;
      }

      @media (min-width: 1024px) {
        font-weight: 400;
      }
    }
  }

  .notched-outline {
    &__leading,
    &__notch,
    &__trailing {
      border: none;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: #000000;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #00000000;
  }
}
