.auth-rules {
  --tickbox-checkbox-radius: 5px;

  margin: 10px 0 0 3px;
  padding: 0;
  display: flex;

  @media (min-width: 645px) {
    margin: 28px 0 0 8px;
  }

  @media (min-width: 1024px) {
    background-color: #042D56;
    margin-left: 0;
    padding: 17px 88px;
    margin-top: 0;
    height: 64px;
  }

  label {
    margin-left: 0;
    font-size: 13px;
    line-height: 1.1;
    color: #ffffff;

    @media (min-width: 645px) {
      margin-left: 20px;
      font-size: 23px;
    }

    @media (min-width: 1024px) {
      font-size: 19px;
      line-height: 1.2;
      margin-left: 5px;
    }

    span:first-child {
      white-space: unset;
      text-overflow: unset;
      overflow: auto;
    }
  }

  a {
    color: #62BEFF;

    @media (min-width: 1024px) {
      color: #ffffff;
    }

    &:hover {
      text-decoration: none;
    }
  }
}
