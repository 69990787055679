.btn-signup-send {
  --button-color: White;
  --button-background: #FA5501;

  background: var(--button-background);
  color: var(--button-color);
  box-sizing: border-box;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  touch-action: manipulation;
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  text-transform: uppercase;
  border: none;
  border-radius: 100px;
  outline: none;
  place-content: center;
  width: -moz-fit-content;
  width: 100%;
  max-width: 250px;
  height: 48px;
  margin: 20px auto 0;
  padding: 0 22px;
  font-family: inherit;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  text-decoration: none;
  display: grid;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 4px 32px rgba(250, 85, 1, 0.95);

  &--desk {
    display: none;

    @media (min-width: 1024px) {
      display: grid;
    }
  }

  &--mobi {
    @media (min-width: 1024px) {
      display: none;
    }
  }

  @media (min-width: 645px) {
    margin: 85px auto 0;
    height: 73px;
    max-width: 395px;
    font-size: 32px;
  }

  @media (min-width: 1024px) {
    max-width: 375px;
    height: 55px;
    font-size: 20px;
    margin: 0;
    margin-left: auto;
  }

  @media (hover: hover) and (pointer: fine) {
    &::before {
      content: '';
      position: absolute;
      inset: 0;
      margin: auto;
      transition: opacity 375ms ease;
    }

    &:hover {
      &::before {
        opacity: 0;
      }
    }

    span {
      position: relative;
      z-index: 1;
    }
  }
}
