.tickbox {
  input {
    &[type="checkbox"]:not([role="switch"]) {
      margin-right: 0;

      @media (min-width: 1024px) {
        margin: 0;
      }
    }

    &[type="checkbox"]:not([role="switch"]):after {
      width: 12px;
      height: 11px;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgZmlsbD0ibm9uZSI+PHBhdGggc3Ryb2tlPSIjZmZmIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS13aWR0aD0iMyIgZD0ibTEuNSA4LjI0MyA0LjY0NyA2Ljk3NmExIDEgMCAwIDAgMS42NzctLjAyTDE2LjUgMS41Ii8+PC9zdmc+);
      background-size: contain;

      @media (min-width: 1024px) {
        width: 16px;
        height: 14px;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMyIgaGVpZ2h0PSIyMCIgZmlsbD0ibm9uZSI+PHBhdGggc3Ryb2tlPSIjMDg0Qjk0IiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIiBzdHJva2Utd2lkdGg9IjQiIGQ9Ik0zLjc1IDExLjg0MiAxMC40MjggMTcgMTkuNzUgMyIvPjwvc3ZnPg==);
      }
    }

    &[type="checkbox"]:not([role="switch"]):before {
      width: 25px;
      height: 25px;
      border-radius: 0;
      background-color: transparent;
      border: 2px solid #61BFFF;

      @media (min-width: 645px) {
        width: 36px;
        height: 36px;
      }

      @media (min-width: 1024px) {
        width: 30px;
        height: 30px;
        background-color: #ffffff;
        border: 3px solid #CFCFCF;
      }
    }

    &[type="checkbox"]:not([role="switch"]):checked:before {
      background-color: transparent;

      @media (min-width: 1024px) {
        background-color: #ffffff;
      }
    }
  }
}
